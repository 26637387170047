<!-- =========================================================================================
    File Name: Chat.vue
    Description: Chat Application - Stay connected
    ----------------------------------------------------------------------------------------
    Item Name:  Kozo Dashboard
      Author:  
    Author URL:  
========================================================================================== -->


<template>
  <div>
    <div class="d-none vx-row">
      <!-- TOP FILTER NAVBAR-->
      <div class="vx-col w-full mb-3">
        <div class="filter-nav">
          <div class="section">
            <ul class="list-inline ml-auto">
              <li class="list-inline-item font-semibold">Add Group</li>
              <li class="list-inline-item font-semibold">|</li>
              <li class="list-inline-item font-semibold">All</li>
              <li class="list-inline-item font-semibold">|</li>
              <li class="list-inline-item font-semibold">Groups</li>
              <li class="list-inline-item font-semibold">|</li>
              <li class="list-inline-item font-semibold">Private</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      id="chat-app"
      class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden"
    >
      <vs-sidebar
        class="items-no-padding"
        parent="#chat-app"
        :click-not-close="clickNotClose"
        :hidden-background="clickNotClose"
        v-model="isChatSidebarActive"
        id="chat-list-sidebar"
      >
        <!-- USER PROFILE SIDEBAR -->
        <!-- <user-profile
          :active="activeProfileSidebar"
          :userId="userProfileId"
          class="user-profile-container"
          @closeProfileSidebar="closeProfileSidebar"
        ></user-profile>-->

        <div class="chat__profile-search flex p-4">
          <!-- <div class="relative inline-flex">
            <vs-avatar
              class="m-0 border-2 border-solid border-white"
              :src="require(`@/assets/images/portrait/small/${activeUserImg}`)"
              size="40px"
              @click="showProfileSidebar(activeUserId)"
            />
            <div
              class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0"
              :class="'bg-' + getStatusColor(true)"
            ></div>
          </div>-->
          <vs-input
            icon="icon-search"
            icon-pack="feather"
            class="w-full mx-5 input-rounded-full no-icon-border"
            placeholder="Search or start a new chat"
            v-model="searchQuery"
            v-on:keyup="searchFilter"
          />
        </div>

        <vs-divider class="d-theme-border-grey-light m-0" />
        <VuePerfectScrollbar class="chat-scroll-area pt-4" :settings="settings">
          <!-- ACTIVE CHATS LIST -->
          <div class="chat__chats-list mb-8 form-title">
            <h4 class="text-primary px-4">Chats</h4>
            <ul class="chat__active-chats bordered-items">
              <li
                class="cursor-pointer"
                v-for="(project , projectIndex) in visibleProjects"
                :key="projectIndex"
                @click="updateActiveChatUser(project,project.project_id,0)"
              >
                <chat-contact
                  :contact="project"
                  :isActiveChatUser="isActiveChatUser(project.project_id)"
                ></chat-contact>
              </li>
            </ul>
          </div>

          <!-- CONTACTS LIST -->
          <!-- <div class="chat__contacts">
            <h3 class="text-primary mb-5 px-4">Contacts</h3>
            <ul class="chat__contacts bordered-items">
              <li
                class="cursor-pointer"
                v-for="contact in chatContacts"
                :key="contact.id"
                @click="updateActiveChatUser(contact.id)"
              >
                <chat-contact :contact="contact"></chat-contact>
              </li>
            </ul>
          </div>-->
        </VuePerfectScrollbar>
      </vs-sidebar>

      <!-- RIGHT COLUMN -->
      <div
        class="chat__bg app-fixed-height chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0"
        :class="{'sidebar-spacer--wide': clickNotClose, 'flex items-center justify-center': activeChatUser === null}"
      >
        <template v-if="activeChatUser">
          <div class="chat__navbar">
            <chat-navbar
              :isSidebarCollapsed="!clickNotClose"
              :user-id="activeChatUser"
              :isPinnedProp="isChatPinned"
              :activeProject="activeProject"
              @openContactsSidebar="toggleChatSidebar(true)"
              @showProfileSidebar="updateUserProfileId"
              @toggleIsChatPinned="toggleIsChatPinned"
            ></chat-navbar>
          </div>
          <VuePerfectScrollbar
            class="chat-content-scroll-area border border-solid d-theme-border-grey-light"
            :settings="settings"
            ref="chatLogPS"
          >
            <div class="chat__log" ref="chatLog">
              <chat-log :chatFeed="chatFeed" :userId="activeChatUser" v-if="activeChatUser"></chat-log>
            </div>
          </VuePerfectScrollbar>
          <div class="chat__input flex p-4 bg-white">
            <vs-input
              class="flex-1"
              placeholder="Type Your Message"
              v-model="typedMessage"
              @keyup.enter="sendMsg"
            />
            <vs-button class="bg-primary-gradient ml-4" type="filled" @click="sendMsg">Send</vs-button>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center">
            <feather-icon
              icon="MessageSquareIcon"
              class="mb-4 bg-white p-8 shadow-md rounded-full"
              svgClasses="w-16 h-16"
            ></feather-icon>
            <h4
              class="py-2 px-4 bg-white shadow-md rounded-full cursor-pointer"
              @click.stop="toggleChatSidebar(true)"
            >Start Conversation</h4>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import contacts from "./contacts";
import ChatContact from "./ChatContact.vue";
import UserProfile from "./UserProfile.vue";
import ChatNavbar from "./ChatNavbar.vue";
import ChatLog from "./ChatLog.vue";
import chatService from "@/services/chatService.js";

export default {
  name: "vx-sidebar",
  data() {
    return {
      chatID: "0",
      allProjects: [],
      visibleProjects: [],
      chatFeed: [],
      activeProject: {},
      active: true,
      isHidden: false,
      contacts: [],
      searchContact: "",
      activeProfileSidebar: false,
      activeChatUser: null,
      userProfileId: -1,
      typedMessage: "",
      isChatPinned: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      },
      clickNotClose: true,
      isChatSidebarActive: true,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    chatLastMessaged() {
      return userId => this.$store.getters["chat/chatLastMessaged"](userId);
    },
    chatUnseenMessages() {
      return userId => {
        const unseenMsg = this.$store.getters["chat/chatUnseenMessages"](
          userId
        );
        if (unseenMsg) return unseenMsg;
      };
    },
    sorted() {
      return this.chats.slice().sort((x, y) => {
        const xId = x.id;
        const yId = y.id;
        const chatDataX = this.$store.getters["chat/chatDataOfUser"](xId);
        const chatDataY = this.$store.getters["chat/chatDataOfUser"](yId);
        return chatDataY.isPinned - chatDataX.isPinned;
      });
    },
    activeUserId() {
      return this.$store.state.AppActiveUser.id;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.img;
    },
    activeUserStatus() {
      return this.$store.state.AppActiveUser.status;
    },
    getStatusColor() {
      return isActiveUser => {
        const userStatus = this.getUserStatus(isActiveUser);

        if (userStatus == "online") {
          return "success";
        } else if (userStatus == "do not disturb") {
          return "danger";
        } else if (userStatus == "away") {
          return "warning";
        } else {
          return "grey";
        }
      };
    },
    chats() {
      return this.$store.getters["chat/chats"];
    },
    chatContacts() {
      return this.$store.getters["chat/chatcontacts"];
    },
    // searchQuery: {
    //   get() {
    //     return this.$store.state.chat.chatSearchQuery;
    //   },
    //   set(val) {
    //     this.$store.dispatch("chat/setChatSearchQuery", val);
    //   }
    // },
    isActiveChatUser() {
      return userId => userId == this.activeChatUser;
    }
  },
  mounted() {
    this.getAllProjects(1);
  },
  methods: {
    searchFilter: function() {
      if (this.searchQuery != "") {
        let search = this.searchQuery.toLowerCase();
        this.visibleProjects = this.allProjects.filter(obj => {
          return obj.project_name.toLowerCase().includes(search);
        });
      } else {
        this.visibleProjects = this.allProjects;
      }
    },
    getAllProjects: function(page) {
      // let loader = this.$loading.show();
      chatService.getAllProjects().then(response => {
        // loader.hide();
        this.isLoading = false;
        console.log("response :", response);
        const res = response.data;
        if (!res.error && res) {
          console.log("res :", res);
          this.allProjects = res.data;
          this.visibleProjects = this.allProjects;
          this.noProjectsData = "No Results Found";
        } else {
          // Show Error Message, Whcih is comming from backend5
        }
      });
    },
    getUserStatus(isActiveUser) {
      return isActiveUser
        ? this.$store.state.AppActiveUser.status
        : this.contacts[this.activeChatUser].status;
    },
    closeProfileSidebar(value) {
      this.activeProfileSidebar = value;
    },
    updateUserProfileId(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    updateActiveChatUser(project, contactId, fetchChat) {
      this.chatFeed = [];
      this.activeChatUser = contactId;
      this.activeProject = project;
      // if (this.$store.getters["chat/chatDataOfUser"](this.activeChatUser)) {
      //   this.$store.dispatch("chat/markSeenAllMessages", contactId);
      // }
      // let chatData = this.$store.getters["chat/chatDataOfUser"](
      //   this.activeChatUser
      // );
      // if (chatData) this.isChatPinned = chatData.isPinned;
      // else this.isChatPinned = false;
      this.toggleChatSidebar();
      // this.typedMessage = "";
      console.log("contactId :", contactId);
      let filter = {
        id: contactId,
        chatID: this.chatID
      };
      if (fetchChat) {
        filter.chatID = fetchChat;
      }
      chatService.getChatByProject(filter).then(response => {
        console.log("response :", response);
        const res = response.data;
        if (!res.error && res) {
          console.log("res :", res);
          if (res.data.length == 0) {
            // this.loadMore = false;
          } else {
            this.chatFeed = res.data;
            // this.chatFeed = this.chatFeed.map(x => {
            //   x.hours = new Date(x.created_date * 1000).getHours();
            //   x.minutes = new Date(x.created_date * 1000).getMinutes();
            //   return x;
            // });
            // this.updateActiveChatUser(contactId, res.data[0].chat_id);
          }
        } else {
          // Show Error Message, Whcih is comming from backend5
        }
      });
    },
    showProfileSidebar(userId) {
      this.userProfileId = userId;
      this.activeProfileSidebar = !this.activeProfileSidebar;
    },
    sendMsg() {
      let that = this;
      let payload = {
        project_id: this.activeChatUser,
        msg: this.typedMessage,
        msg_status: "1",
        chat_active: "1"
      };
      that.typedMessage = "";
      chatService
        .addChat(payload)
        .then(response => {
          console.log("response :", response);
          const res = response.data;
          if (!res.error && res) {
            this.chatFeed.push(res.data.chat);
            this.chatFeed = this.chatFeed.map(x => {
              x.hours = new Date(x.created_date * 1000).getHours();
              x.minutes = new Date(x.created_date * 1000).getMinutes();
              return x;
            });
            this.typedMessage = "";
          } else {
            VueMain.$toast.open({
              message: "Some Error Occured",
              type: "error",
              position: "top"
            });
          }
        })
        .catch(err => {
          VueMain.$toast.open({
            message: "Some Error Occured",
            type: "error",
            position: "top"
            // all other options
          });
        });
      // if (!this.typedMessage) return;
      // const payload = {
      //   isPinned: this.isChatPinned,
      //   msg: {
      //     textContent: this.typedMessage,
      //     time: String(new Date()),
      //     isSent: true,
      //     isSeen: false
      //   },
      //   id: this.activeChatUser
      // };
      // this.$store.dispatch("chat/sendChatMessage", payload);
      // this.typedMessage = "";
      this.$refs.chatLogPS.$el.scrollTop = this.$refs.chatLog.scrollHeight;
    },
    toggleIsChatPinned(value) {
      this.isChatPinned = value;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.isChatSidebarActive = this.clickNotClose = false;
      } else {
        this.isChatSidebarActive = this.clickNotClose = true;
      }
    },
    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return;
      this.isChatSidebarActive = value;
    }
  },
  components: {
    VuePerfectScrollbar,
    ChatContact,
    UserProfile,
    ChatNavbar,
    ChatLog
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/apps/chat.scss";
</style>
